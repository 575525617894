import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

let masonryContainers = document.getElementsByClassName("masonry-grid");

// Only call Masonry before images have loaded if we're on a wider screen.
// If we call this on mobile (i.e. less than 768px) before the images are loaded, it doesn't flow properly.
if (window.innerWidth >= 768) {
  for (let i = 0; i < masonryContainers.length; i++) {
    // console.log("Setting Masonry on wide container", masonryContainers[i]);
    new Masonry(masonryContainers[i], {
      itemSelector: ".masonry-item",
      gutter: 10,
      percentPosition: true,
    });
  }
}

// Once everything is loaded we call Masonry again to ensure it flows correctly, especially if we
// resize the screen up from a mobile size.
//
// This will leak some masonry objects but that isn't too big an issue and recalling from scratch seems more reliable.
imagesLoaded(masonryContainers, function (_context) {
  // console.log("Images have loaded");

  // Reflow masonry on all screens
  for (let i = 0; i < masonryContainers.length; i++) {
    new Masonry(masonryContainers[i], {
      itemSelector: ".masonry-item",
      gutter: 10,
      percentPosition: true,
    });
  }
});

// Add mobile nav handler
let mobileNav = document.querySelector("#mobile-nav");
let h2Element = document.querySelector("#mobile-nav h2");

if (h2Element) {
  h2Element.addEventListener("click", function (event) {
    let navClosed = mobileNav.classList.contains("mobile-nav-closed");

    navClosed = !navClosed;

    if (navClosed) {
      mobileNav.classList.remove("mobile-nav-open");
      mobileNav.classList.add("mobile-nav-closed");
    } else {
      mobileNav.classList.remove("mobile-nav-closed");
      mobileNav.classList.add("mobile-nav-open");
    }
  });
}
